<template>
    <div class="w-full bg-[#65737E] shadow-inner">
        <div class="max-w-5xl max-md:max-w-3xl flex flex-col mx-auto justify-center h-[200px] max-md:h-[150px] px-2 sm:px-6 lg:px-8">
            <h1 class="text-3xl sm:text-5xl md:text-6xl font-extrabold text-white font-montserrat">
                Monitor Politic
            </h1>
            <h2 class="block text-xl sm:text-2xl text-white">
                Știri politice din mai multe perspective.
            </h2>
        </div>
    </div>

    <div class="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8 py-2">
        <SearchBar
            :placeholder="searchPlaceholder"
            :filters-visible="filtersVisible"
            @toggle-filters="toggleFilters"
            @search="searchArticles"
        />

        <FiltersPanel
            v-if="filtersVisible"
            :visible="filtersVisible"
            :sources="sourceCategories"
            :parties="partiesForFilter"
            @toggle-filters="toggleFilters"
            @reset-filters="resetFilters"
            @apply-filters="applyFilters"
        />
    </div>
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <TrendingArticlesTabs
            ref="trendingTabs"
            :filters="filters"
        />
    </div>
</template>

<script>
import SearchBar from "../Components/SearchBar.vue";
import FiltersPanel from "../Components/FiltersPanel.vue";
import TrendingArticlesTabs from "@/Pages/TrendingArticlesTabs.vue";

export default {
    components: {
        TrendingArticlesTabs,
        SearchBar,
        FiltersPanel,
    },

    props: {
        sourceCategories: Object,
        partiesForFilter: Object,
        seo: Object,
    },

    data() {
        return {
            searchPlaceholder: "Caută știri...",
            filtersVisible: false,
            filters: {
                search: null,
                selectedSource: null,
                politicalOrientation: null,
                subjectivityThreshold: null,
                subjectivityComparison: null,
                objectivityThreshold: null,
                objectivityComparison: null,
                selectedParty: null,
            },
        };
    },

    methods: {
        toggleFilters() {
            this.filtersVisible = !this.filtersVisible;
        },

        updateURLParams() {
            const params = new URLSearchParams(window.location.search);
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) {
                    params.set(key, this.filters[key]);
                } else {
                    params.delete(key);
                }
            });
            window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
        },

        searchArticles(searchQuery) {
            this.filters.search = searchQuery;
            this.updateURLParams();
        },

        applyFilters(filters) {
            this.filters = {...this.filters, ...filters};
            this.updateURLParams();
        },

        resetFilters() {
            this.filters = {
                search: null,
                selectedSource: null,
                politicalOrientation: null,
                subjectivityThreshold: null,
                subjectivityComparison: null,
                objectivityThreshold: null,
                objectivityComparison: null,
                selectedParty: null,
            };
            this.updateURLParams();
        },
    },

    created() {
        // Load any URL params into filters on creation
        const params = new URLSearchParams(window.location.search);
        Object.keys(this.filters).forEach(key => {
            const value = params.get(key);
            if (value) {
                this.filters[key] = value;
            }
        });
    },
};
</script>
